.select button:global(.lds-select--select-button) {
  @apply h-[2.875rem];
  @apply min-w-[8rem];
  padding: calc(var(--spacing-user-menu-padding-block) - 1px) calc(var(--spacing-user-menu-padding-inline) - 1px);
  padding-right: 1px;
}

.select span:global(.lds-select--item) {
  @apply flex gap-[var(--spacing-select-gap)] p-0 h-auto;
}
.select li:global(.lds-select--item) {
  @apply flex gap-[var(--spacing-select-gap)];
}

.select .selectItem {
  padding: calc(var(--spacing-user-menu-padding-block) - 1px) calc(var(--spacing-user-menu-padding-inline) - 1px) calc(var(--spacing-user-menu-padding-block) - 1px) calc(var(--spacing-user-menu-padding-inline) - var(--spacing-xs) - 1px);
}

/**
 * Override default select colors to match the new nav
 */
.select ul:global(.lds-select--dropdown) {
  @apply -mt-[1px];
  border-color: var(--color-border-side-nav-item-active-default);
}
.select ul :global(.lds-select--item):not(:first-child) {
  border-top: 1px solid transparent;
}
.select ul :global(.lds-select--item):not(:last-child) {
  border-bottom: 1px solid transparent;
}
.select ul :global(.lds-select--item):not(:global(.lds-select--selected)):hover {
  background: var(--color-background-side-nav-item-hover-default);
}
.select ul :global(.lds-select--item):global(.lds-select--selected) {
  color: var(--color-font-side-nav-item-active-default);
  background: var(--color-background-side-nav-item-active-default);
  border-color: var(--color-border-side-nav-item-active-default);
}

.select :global(.lds-select--selected) :global(.lds-user-menu--name),
.select :global(.lds-select--selected) :global(.lds-user-menu--username) {
  @apply text-accent;
}

.profileBlock {
  @apply max-w-full;
}
