.multiActions, .checkbox {
  @apply invisible md:visible;
}
.description {
  @apply leading-normal text-sm;
}
button.rejectButton > span {
  @apply text-danger;
}
.emailLink {
  @apply no-underline hover:underline text not-italic;
}
