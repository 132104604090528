.lensID {
  @apply grid text-left;
  grid-template-columns: 3.3rem 1fr;
  max-width: 25rem;
}

.lensID :global(.lds-profile-block--avatar) {
  @apply rounded-full self-center;
}

.role {
  @apply ml-md;
}
