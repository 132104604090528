.autoJoinSwitchWrapper {
  @apply mb-2xl;
  @apply grid grid-flow-col gap-5;
}

.switch {
  @apply self-start place-self-end;
}

.switchLabel {
  margin-bottom: var(--spacing-form-switch-group-label-margin-block-end);
}

.switchLabel, .switchDescription {
  @apply ml-0;
}

.link {
  @apply underline;
  @apply focus:outline-none;
  color: var(--link-color);
  font-family: var(--typography-font-primary);
}
