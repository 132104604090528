.tabs {
  @apply flex gap-md flex-col md:flex-row md:justify-between md:items-baseline;
  @apply my-lg md:mt-sm md:mb-lg;
}

.tabs > span {
  @apply text-sm;
}

.heading {
  @apply my-sm;
}

.manageUsersSection {
  @apply flex items-center justify-between w-full;
  @apply mb-sm;
}

.manageUsersSectionTitle {
  @apply uppercase !text;
}

.seatInfo {
  @apply mt-lg sm:mt-0;
  @apply uppercase text-secondary;
}

.noSeats {
  @apply flex gap-sm items-center;
}

.seatCount {
  @apply text-accent mr-2;
}
